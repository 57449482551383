/**
 * External dependencies
 */

const CustomEvent = window.CustomEvent || null

/**
 * Wrapper function to dispatch an event.
 */
export const dispatchEvent = (
	name,
	{
		bubbles = false,
		cancelable = false,
		element,
		detail = {},
	}
) => {
	if ( ! CustomEvent ) {
		return;
	}
	if ( ! element ) {
		element = document.body;
	}
	const event = new CustomEvent( name, {
		bubbles,
		cancelable,
		detail,
	} );
	element.dispatchEvent( event );
};
