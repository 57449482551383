/**
 * External dependencies
 */
import { createContext, useContext, useState } from '@wordpress/element';

export const SearchViewContext = createContext({});

export const SearchViewProvider = ({children, size='small'}) =>{
    const [inputFocused, setInputFocused] = useState(null);
    const [query,setQuery] = useState('');
    const updateInputFocused = (value) => {
        setInputFocused(value);
    };

    const updateQuery = ( value) =>{
        setQuery(value);
    }

    return <SearchViewContext.Provider
        value={{
            inputFocused,
            setInputFocused:updateInputFocused,
            query,
            setQuery:updateQuery,
            size
    }}>
        {children}
    </SearchViewContext.Provider>
}

export const useSearchContext = () => {
    return useContext(SearchViewContext);
};