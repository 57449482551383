/**
 * External dependencies
 */
import {lazy} from '@wordpress/element';
import {registerSearchBlock} from '../../../packages/search'

/**
 * Internal dependencies
 */
import metadata from './component-metadata';
import {YWCAS_BUILD_BLOCK_URL} from '../../../config';
import RELATED_POSTS_BLOCK from "./related-posts-block/block.json";


// Modify webpack publicPath at runtime based on location of WordPress Plugin.
// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = YWCAS_BUILD_BLOCK_URL;

registerSearchBlock({
        metadata: metadata.INPUT_BLOCK,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "input-block/input" */
                    './input-block/frontend'
                    )
        ),
    }
);

registerSearchBlock({
    metadata: metadata.HISTORY_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "history-block/history-searches" */
                './history-block/frontend'
                )
    )
});
registerSearchBlock({
    metadata: metadata.POPULAR_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "popular-block/popular-searches" */
                './popular-block/frontend'
                )
    )
});
registerSearchBlock({
    metadata: metadata.RELATED_CATEGORY_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "related-categories-block/related-category" */
                './related-categories-block/frontend'
                )
    )
});
registerSearchBlock({
    metadata: metadata.RELATED_POSTS_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "related-posts-block/related-post" */
                './related-posts-block/frontend'
                )
    )
})
registerSearchBlock({
    metadata: metadata.EMPTY_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "empty-block/empty" */
                './empty-block/frontend'
                )
    )
});
registerSearchBlock({
    metadata: metadata.FILLED_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "filled-block/filled" */
                './filled-block/frontend'
                )
    )
});

registerSearchBlock({
    metadata: metadata.PRODUCT_RESULTS_BLOCK,
    component: lazy(
        () =>
            import(
                /* webpackChunkName: "product-results-block/product-results" */
                './product-results-block/frontend'
                )
    )
});
