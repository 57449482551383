/**
 * External dependencies
 */
import { render, Suspense } from '@wordpress/element';


// Some blocks take care of rendering their inner blocks automatically. For
// example, the empty cart. In those cases, we don't want to trigger the render
// function of inner components on load. Instead, the wrapper block can trigger
// the event `ywcas-blocks_render_blocks_frontend` to render its inner blocks.
const selectorsToSkipOnLoad = [ '.wp-block-yith-search-block' ];

/**
 * Renders a block component in a single `container` node.
 */
export const renderBlock = ( {
	Block,
	container,
	attributes = {} ,
	props = {} ,
	errorBoundaryProps = {},
}) => {
	render(
		<Suspense fallback={ <div className="wc-block-placeholder"/> }>
				{ Block && <Block { ...props } attributes={ attributes } /> }
			</Suspense>,
		container,
		() => {
			if ( container.classList ) {
				container.classList.remove( 'is-loading' );
			}
		}
	);
};

/**
 * Renders a block component in each `containers` node.
 */
const renderBlockInContainers = ( {
	Block,
	containers,
	getProps = () => ( {}  ),
	getErrorBoundaryProps = () => ( {} ),
}) => {
	if ( containers.length === 0 ) {
		return;
	}

	// Use Array.forEach for IE11 compatibility.
	Array.prototype.forEach.call( containers, ( el, i ) => {
		const props = getProps( el, i );

		const errorBoundaryProps = getErrorBoundaryProps( el, i );
		const attributes = {
			...el.dataset,
			...( props.attributes || {} ),
		};
		renderBlock( {
			Block,
			container: el,
			props,
			attributes,
			errorBoundaryProps,
		} );
	} );
};

// Given an element and a list of wrappers, check if the element is inside at
// least one of the wrappers.
const isElementInsideWrappers = (
	el,
	wrappers
) => {
	return Array.prototype.some.call(
		wrappers,
		( wrapper ) => wrapper.contains( el ) && ! wrapper.isSameNode( el )
	);
};

/**
 * Renders the block frontend in the elements matched by the selector which are
 * outside the wrapper elements.
 */
const renderBlockOutsideWrappers = ( {
	Block,
	getProps,
	getErrorBoundaryProps,
	selector,
	wrappers,
} ) => {
	const containers = document.body.querySelectorAll( selector );

	// Filter out blocks inside the wrappers.
	if ( wrappers && wrappers.length > 0 ) {
		Array.prototype.filter.call( containers, ( el ) => {

			return ! isElementInsideWrappers( el, wrappers );
		} );
	}

	renderBlockInContainers( {
		Block,
		containers,
		getProps,
		getErrorBoundaryProps,
	} );
};

/**
 * Renders the block frontend in the elements matched by the selector inside the
 * wrapper element.
 */
const renderBlockInsideWrapper = ( {
	Block,
	getProps,
	getErrorBoundaryProps,
	selector,
	wrapper,
} ) => {
	const containers = wrapper.querySelectorAll( selector );
	renderBlockInContainers( {
		Block,
		containers,
		getProps,
		getErrorBoundaryProps,
	} );
};


/**
 * Renders the block frontend on page load. If the block is contained inside a
 * wrapper element that should be excluded from initial load, it adds the
 * appropriate event listeners to render the block when the
 * `wc-blocks_render_blocks_frontend` event is triggered.
 */
export const renderFrontend = (
	props
) => {
	const wrappersToSkipOnLoad = document.body.querySelectorAll(
		selectorsToSkipOnLoad.join( ',' )
	);

	const { Block, getProps, getErrorBoundaryProps, selector } = props;
	renderBlockOutsideWrappers( {
		Block,
		getProps,
		getErrorBoundaryProps,
		selector,
		wrappers: wrappersToSkipOnLoad,
	} );
	// For each wrapper, add an event listener to render the inner blocks when
	// `wc-blocks_render_blocks_frontend` event is triggered.
	Array.prototype.forEach.call( wrappersToSkipOnLoad, ( wrapper ) => {

		wrapper.addEventListener( 'ywcas-blocks_render_blocks_frontend', () => {
			renderBlockInsideWrapper( { ...props, wrapper } );
		} );
	} );
};

export default renderFrontend;
