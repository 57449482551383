import parse from 'html-react-parser';
import {ywcas_wc_data} from '../../config';
import {useMemo,useEffect,useState} from "react";
/**
 * Check if an element object or array is empty
 *
 * @param element
 * @returns {boolean}
 */
export const isEmpty = (element) => {
  if (typeof element === 'undefined') {
    return true;
  }

  if (typeof element === 'object') {
    return Object.keys(element).length === 0;
  }

  return element.hasOwnProperty(length) ? element.length === 0 : 'undefined';
};

export const formatSinglePrice = price => accounting.formatMoney(price, ywcas_wc_data.currency);

export const formatPrice = (max, min = 0) => {
  const maxFormatted = formatSinglePrice(max);
  if (!parseFloat(min) || min === max) {
    return maxFormatted;
  }

  const minFormatted = formatSinglePrice(min);

  return `${minFormatted} - ${maxFormatted}`;
};

/**
 * Return the price of a product
 *
 * @param item
 * @returns {*|string}
 */
export const getPrice = item => {
  if (item.product_type === 'variable') {
    return formatPrice(item['max_price'], item['min_price']);
  } else {
    if (parseInt(item.onsale)) {
      return formatPrice(item['min_price']);
    }
  }

  return formatPrice(item['max_price']);
};

export const getWordStr = (str, numOfWords) => {
  return  str.split(' ').slice(0, numOfWords).join(" ");
};

const escapeRegExChars = value  => {
  return value ? value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") : value;
}

export const formatString = ( query, currentText ) =>{
  var pattern = '(' + escapeRegExChars(query) + ')';
  return parse( currentText.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>') );
}

export const getTopFrequentCategories = (numbers, numberOfResults = 3) => {
  // Count the occurrences of each number
  const frequencyMap = new Map();
  for (const number of numbers) {
    if (frequencyMap.has(number)) {
      frequencyMap.set(number, frequencyMap.get(number) + 1);
    } else {
      frequencyMap.set(number, 1);
    }
  }
  // Sort the frequency map by values in descending order
  const sortedFrequency = [...frequencyMap.entries()].sort((a, b) => b[1] - a[1]);

  return sortedFrequency.slice(0, numberOfResults).map(entry => entry[0]);
}

export const useMediaQuery = (query) => {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [match, setMatch] = useState(mediaQuery.matches);

  useEffect(() => {
    const onChange = () => setMatch(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    return () => mediaQuery.removeEventListener("change", onChange);
  }, [mediaQuery]);

  return match;
}