/**
 * Internal dependencies
 */

import { registeredBlocks } from './registered-blocks';


const innerBlockAreas = {
	YITH_SEARCH: 'yith/search-block',
	YITH_SEARCH_INPUT: 'yith/input-block',
	YITH_EMPTY: 'yith/empty-block',
	YITH_FILLED: 'yith/filled-block',
}
/**
 * Check if a block/area supports inner block registration.
 */
export const hasInnerBlocks = ( block ) => {
	return Object.values( innerBlockAreas ).includes(
		block
	);
};

/**
 * Returns an array of registered block objects available within a specific parent block/area.
 */
export const getRegisteredBlocks = (
	block
) => {

	return hasInnerBlocks( block )
		? Object.values( registeredBlocks ).filter( ( { metadata } ) =>
				( metadata?.parent || [] ).includes( block )
		  )
		: [];
};
