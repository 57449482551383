import React from 'react';
import {Box} from '@lapilli-ui/components';
import classnames from 'classnames';

import {SlotFillProvider} from '../../packages/search'
import {SearchViewProvider} from "./context";
import {getSizeClassName} from "../../common";

const Block = ({children, attributes}) => {
    const {size} = attributes;
    const classes = classnames(
        'ywcas-classic-search',
        getSizeClassName(size)
    );
    return (
        <SlotFillProvider>
            <SearchViewProvider size={size}>
                <Box className={classes}>
                    {children}
                </Box>
            </SearchViewProvider>
        </SlotFillProvider>
    );
};

export default Block;