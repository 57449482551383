/**
 * Internal dependencies
 */
import FILLED_BLOCK from './filled-block/block.json';
import EMPTY_BLOCK from './empty-block/block.json';
import INPUT_BLOCK from './input-block/block.json';
import HISTORY_BLOCK from './history-block/block.json';
import POPULAR_BLOCK from './popular-block/block.json';
import PRODUCT_RESULTS_BLOCK from './product-results-block/block.json';
import RELATED_CATEGORY_BLOCK from './related-categories-block/block.json';
import RELATED_POSTS_BLOCK from './related-posts-block/block.json';

export default {
  FILLED_BLOCK,
  EMPTY_BLOCK,
  INPUT_BLOCK,
  HISTORY_BLOCK,
  POPULAR_BLOCK,
  PRODUCT_RESULTS_BLOCK,
  RELATED_CATEGORY_BLOCK,
  RELATED_POSTS_BLOCK
};
